<template>
    <div>
        <el-divider></el-divider>
        <el-col :span="12">
            <el-input :placeholder="$t('lang.请输入序列号')" v-model="searchObj.onlyText" @change="getGroupList(1)"><el-button slot="append" icon="el-icon-search" @click="getGroupList(1)"></el-button></el-input>
            <vxe-table ref="groupTable" :data="tableData" border @current-change="currentChange" :row-config="rowConfig" :loading="loading" style="margin-top:10px;">
                <vxe-column field="serialNumber" :title="$t('lang.序列号')" width="120"></vxe-column>
                <vxe-column field="gouJian" :title="$t('lang.构件')"></vxe-column>
                <vxe-column field="maxFengKuan" :title="$t('lang.最大缝宽') + '(mm)'" align="right" header-align="right"></vxe-column>
                <vxe-column field="minFengKuan" :title="$t('lang.最小缝宽') + '(mm)'" align="right" header-align="right"></vxe-column>
                <vxe-column field="avgFengKuan" :title="$t('lang.平均缝宽') + '(mm)'" align="right" header-align="right"></vxe-column>
                <vxe-column field="remark" :title="$t('lang.备注')"></vxe-column>
                <vxe-column :title="$t('lang.操作')" width="80">
                    <template #default="{ row }"><el-button @click="exportData(row)" type="primary" size="mini">导出</el-button></template>
                </vxe-column>
            </vxe-table>
            <vxe-pager align="left" @page-change="getGroupList" :page-size.sync="searchObj.pageSize" :current-page.sync="searchObj.currentPage" :total="searchObj.total" :layouts="['PrevPage', 'Jump', 'PageCount', 'NextPage', 'Total']">
            </vxe-pager></el-col>
        <el-col :span="11" :offset="1">
            <el-tabs @tab-click="tabClick" v-model="tabName" v-loading.fullscreen.lock="tabLoading" :element-loading-text="$t('lang.加载中')" element-loading-spinner="el-icon-loading">
                <el-tab-pane v-for="(item, index) in tabData" :key="index" :label="item.testTime">
                    <el-descriptions>
                        <el-descriptions-item :label="$t('lang.测点')">{{ item.ceDian }}</el-descriptions-item>
                        <el-descriptions-item :label="$t('lang.缝宽') + '(mm)'"><el-tag type="danger" size="small">{{
                            item.fengKuan
                        }}</el-tag></el-descriptions-item>
                    </el-descriptions>
                    <el-image :src="item.src" fit="fill"></el-image>
                </el-tab-pane>
            </el-tabs></el-col>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tabName: "0",
            loading: false,
            tabLoading: false,
            rowConfig: { isCurrent: true, isHover: true },
            searchObj: {
                onlyText: "",
                currentPage: 1,
                pageSize: 10,
                total: 0,
            },
            tableData: [],
            tabData: [],
        };
    },
    methods: {
        getGroupList(v) {
            let that = this;
            that.loading = true;
            if (v) {
                that.currentPage = 1;
            }
            that.axios.post("CFY001/GetGroupList", that.searchObj).then((response) => {
                that.tableData = response.data.data.data;
                that.searchObj.total = response.data.data.total;
                that.loading = false;
                if (that.tableData.length > 0) {
                    that.$refs.groupTable.setCurrentRow(that.tableData[0]);
                    that.currentChange({ newValue: that.tableData[0] });
                }
            });
        },
        currentChange({ newValue }) {
            let that = this;
            that.tabName = "0";
            that.axios
                .post("CFY001/GetTabInfo", {
                    serialNumber: newValue.serialNumber,
                    gouJian: newValue.gouJian,
                })
                .then((response) => {
                    that.tabData = response.data.data;
                    if (that.tabData.length > 0) {
                        that.tabClick({ index: 0 });
                    }
                });
        },
        tabClick(v) {
            let that = this;
            that.tabLoading = true;
            that.axios
                .post("CFY001/GetImageInfo", {
                    serialNumber: that.$refs.groupTable.currentRow.serialNumber,
                    gouJian: that.$refs.groupTable.currentRow.gouJian,
                    testTime: that.tabData[v.index].testTime,
                })
                .then((response) => {
                    that.$set(that.tabData[v.index], "src", "data:image/jpeg;base64," + response.data.data.pic);
                    that.tabLoading = false;
                });
        },
        exportData(row) {
            let that = this;
            that.tabLoading = true;
            that.axios.post("CFY001/Export", row, { responseType: "blob" }).then((response) => {
                that.tabLoading = false;
                let b = new Blob([response.data], {
                    type: "application/vnd.ms-excel;charset=utf-8",
                });
                let downloadElement = document.createElement("a");
                let href = window.URL.createObjectURL(b); // 创建下载的链接
                downloadElement.href = href;
                downloadElement.download = row.gouJian + ".xlsx"; // 下载后文件名
                document.body.appendChild(downloadElement);
                downloadElement.click(); // 点击下载
                document.body.removeChild(downloadElement); // 下载完成移除元素
                window.URL.revokeObjectURL(href); // 释放掉blob对象
            });

        }
    },
    mounted() {
        let that = this;
        that.getGroupList();


    },
};
</script>

<style></style>